import axios, { AxiosInstance } from 'axios';

const apiClient: AxiosInstance = axios.create({
    baseURL: window.location.origin + process.env.REACT_APP_API_BASE_URL ??
        'http://localhost:8091/api',
    headers: {
        'Content-Type': 'application/json', // Set the default content type
    },

});

export default apiClient;